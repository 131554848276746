import axiosApi from "../axios";
export default {
  async signUp(User) {
    return await axiosApi
      .post(`/auth/signup`, User)
      .then((res) => {
        console.log(res.data + "this is data ");
        return res.data;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );

        return e.response?.data?.errors;
      });
  },
  async login(User) {
    return await axiosApi
      .post(`/auth/login`, User)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((e) => {
        console.log(JSON.stringify(e.response.data) + "this is an error ");

        return e.response?.data;
      });
  },
  async sendForgetPasswordCode(User) {
    return await axiosApi
      .post(`/auth/forgotPassword`, User)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((e) => {
        console.log(JSON.stringify(e.response.data) + "this is an error ");
        return e.response.data;
      });
  },
  async verifyResetCode(body) {
    return await axiosApi
      .post(`/auth/verifyResetCode`, body)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((e) => {
        console.log(JSON.stringify(e.response.data) + "this is an error ");
        return e.response.data;
      });
  },
  async resetPassword(body) {
    return await axiosApi
      .put(`/auth/resetPassword`, body)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((e) => {
        console.log(JSON.stringify(e.response.data) + "this is an error ");
        return e.response.data;
      });
  },
};
